import * as React from "react"
import customersData from "../data/customers.json"

export type Customer = {
  name: string
  location: string
}

function Customers() {
  const customers: Customer[] = customersData
  return (
    <div className="container mt-4 customers">
      <div className="row">
        <div className="col-12 mb-4">
          <h2>Our Customers</h2>
        </div>
        {customers.map(customer => (
          <div  data-aos="fade-up" className="col-sm-12 col-lg-4 text-center">
            <h4 className="display-6">{customer.name}</h4>
            <p>{customer.location}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Customers
