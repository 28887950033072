import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import emailjs from "emailjs-com"

function ContactUsForm() {
  React.useEffect(() => {
    emailjs.init("user_VGRPDRDPlhk2l0Lh0AdQ9")
  })

  async function handleSubmit() {
    return submitMail(
      (document.getElementById("customerName") as HTMLInputElement).value,
      (document.getElementById("customerEmail") as HTMLInputElement).value,
      (document.getElementById("customerPhone") as HTMLInputElement).value,
      (document.getElementById("customerAddress") as HTMLInputElement).value,
      (document.getElementById("subject") as HTMLInputElement).value,
      (document.getElementById("message") as HTMLInputElement).value
    )
  }

  async function submitMail(
    customerName: string,
    customerEmail: string,
    customerPhone: string,
    customerAddress: string,
    subject: string,
    message: string
  ) {
    try {
      await emailjs.send("default_service", "template_ydub7us", {
        reply_to: customerEmail,
        from_name: customerName,
        subject: subject,
        message: message + "\n\nAddress:\n" + customerAddress,
        phone: customerPhone,
      })

      alert("Email sent!")
      window.location.reload()
    } catch (e) {
      alert("Cuuld not send email. Please try again.")
    }
  }

  return (
    <div className="container mt-4 contact-us" id="contact-us">
      <div className="col-12 mb-4">
        <h2>Contact Us</h2>
      </div>
      <div className="row"></div>
      <div className="row bg-grey py-4">
        <div className="col-sm-12 col-lg-6 text-sm-center text-lg-start">
          <p className="col-12 ">
            Phone: <b>{"082 299 2331 or 067 717 6708"}</b>
          </p>
          <p className="col-12">
            Email: <b>{"info@mikubasecurityandprojects.co.za"}</b>
          </p>
          <p className="col-12">
            Address: <b>{"64 Goldsmith Street, Saltriver, 7925"}</b>
          </p>
          <div className="col-12 mb-3">
            <StaticImage
              src="../images/contact_us.png"
              alt="Mikuba Security7 Services logo"
              formats={["avif", "webp", "auto"]}
            />
          </div>
        </div>
        <form className="col-sm-12 col-lg-6">
          <div className="row g-3">
            <div className="col-sm-12 col-lg-6">
              <input
                id="customerName"
                type="text"
                className="form-control"
                placeholder="Name"
                aria-describedby="customerName"
                required
              />
            </div>
            <div className="col-sm-12 col-lg-6">
              <input
                id="customerEmail"
                type="email"
                className="form-control"
                placeholder="Email"
                aria-describedby="customerEmail"
                required
              />
            </div>
            <div className="col-sm-12 col-lg-6">
              <input
                id="customerPhone"
                type="phone"
                className="form-control"
                placeholder="Phone (Optional)"
                aria-describedby="customerPhone"
              />
            </div>
            <div className="col-sm-12 col-lg-6">
              <input
                id="customerAddress"
                type="text"
                className="form-control"
                placeholder="Customer's Address (Optional)"
                aria-describedby="customerAddress"
              />
            </div>
            <div className="col-12">
              <input
                id="subject"
                type="text"
                className="form-control"
                placeholder="Subject"
                aria-describedby="subject"
                required
              />
            </div>
            <div className="col-12">
              <textarea
                id="message"
                className="w-100"
                placeholder="Type your query here"
                required
              />
            </div>
            <div className="col-12">
              <button
                className="btn btn-primary w-100"
                onClick={e => {
                  e.preventDefault()
                  handleSubmit()
                }}
              >
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactUsForm
