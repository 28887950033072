import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

function HtmlContent() {
  return (
    <div className="container mt-4 html-content">
      <h2 id="aboutus" data-aos="fade-up">ABOUT US</h2>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/55cab539-5975-4b9d-97ad-8c96d40c92a9.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<p data-aos="fade-up"><strong>MIKUBA SECURITY AND PROJECTS (MISEP)</strong> is duly incorporated and registered under the companies Act, Laws of South Africa. As a limited company, it transacts business as laid down in the Companies Act Chapter laws of South Africa.</p>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/f5fded6e-ccf8-4623-a19e-b2dd7b540ca1.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<p data-aos="fade-up">The company is fully authorized and licensed to conduct business in South Africa having started our operations in the year 2016 and we continue to grow our operations.&nbsp;
Currently we are well presented within the city of Cape Town Observatory with a vision to spread even further to other towns.&nbsp;</p>
<p data-aos="fade-up">The board of Directors is drawn from a wide field of experience in the service industry and with high qualifications in security, finance, accounting, engineering, human resource, and administration.&nbsp;
The senior management consists of individuals with experience in the security industry. Managers who have worked in the leading and recognized security firms in senior positions over a number of years and are well recognized. Further, we have a team of qualified operational and training staff who can handle and deliver to the required standards</p>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/e4f851aa-0152-4d5f-9127-e8355ec26157.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/3bc191a2-2dfe-4ff1-8953-f5805da16181.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<blockquote data-aos="fade-up">
  <p>We seek to be a professional and progressive company in our quest to be proactive in the delivery of security solutions to our customers.</p>
</blockquote>
<p data-aos="fade-up">As an organization, we build our business on the following core values:</p>
<ol data-aos="fade-up">
<li><p><strong>The best Human Resource</strong>: As one of the most important aspect of our operations we endeavor to hire, train and maintain well motivated personnel.</p></li>
<li><p><strong>Absolute Integrity</strong>: We seek to deliver real value for money to our esteemed customers through open liaison and consultations.</p></li>
<li><p><strong>Solutions Provider</strong>: We use proprietary processes and extensive experience to deliver expert solutions in all our service lines. This ensures improved management, safety, security, demonstrable risk and loss reduction.</p></li>
<li><p><strong>Innovation</strong>: We seek to be a professional and progressive company in our quest to be proactive in the delivery of security solutions to our customers.</p></li>
<li><p><strong>Service with Passion</strong>: We seek to passionately deliver services as our way of doing business.</p></li>
</ol>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/d7d9eff3-5bc5-414d-bfbd-a63bbac4de55.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<blockquote data-aos="fade-up">
  <p>We take time to examine and analyze the issues to be addressed in considerable depth prior to any significant deployment of our personnel.</p>
</blockquote>
<ul data-aos="fade-up">
<li><p>Full presentation of mutual capabilities</p></li>
<li><p>Overall evaluation of operational requirements</p></li>
<li><p>Preparation and delivery of operational proposal</p></li>
<li><p>Contractual arrangements put in place</p></li>
<li><p>Engagement of Mikuba security and projects services</p></li>
<li><p>Thorough project plan worked up and agreed</p></li>
<li><p>Deployment of Mikuba security and projects guards</p></li>
<li><p>Completion of project</p></li>
<li><p>Withdrawal of guards upon expiry of contract</p></li>
</ul>
<h2 id="services" data-aos="fade-up">SERVICES</h2>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/0b719359-79f0-4c97-a169-daffdc7cc57d.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<ul data-aos="fade-up">
<li><p>24 hours cover in case of emergency</p></li>
<li><p>In depth investigation and liaison with the police by our investigations officers in case of an incident.</p></li>
<li><p>To our Directors, General Manager, Operations Manager, Branch Manager and other senior supervisors.</p></li>
<li><p>Replacement/standby guard to be deployed in case the regular guard is sick or absent.</p></li>
<li><p>Reliever guard to relieve the regular guard during off duties every week.</p></li>
</ul>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/50e73a20-a7e3-4fd4-858e-91f951568fe7.jpeg" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/21432720-ace6-4a54-ac3b-d3941746083a.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<p data-aos="fade-up">All Mikuba security and projects guarding personnel are equipped with practical and durable uniforms and supporting kit as follows:</p>
<ol data-aos="fade-up">
<li><p>2 pairs of uniform</p></li>
<li><p>A lanyard/whistle</p></li>
<li><p>Pair of boots</p></li>
<li><p>Raincoat</p></li>
<li><p>Torch</p></li>
<li><p>Sweater</p></li>
</ol>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/bd296f27-03b1-4dce-8dc9-f5b464f6f4c4.jpeg" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/67cac7dd-caf5-4e69-8d7d-1e90e907a91d.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<p data-aos="fade-up">Mikuba security and projects Ltd is one of the fast growing security firms in South Africa and our dedicated, close-knit staff pool is comprised of professional and experienced employee with most of them drawn from the private security backgrounds, supplemented with relevant customized security training per assignment.</p>
<p data-aos="fade-up">Mikuba security and projects is specialized in personnel protection, property protection, safe journey management, event security, security consulting for individuals or businesses, and installation &amp; maintenance of technology based systems (Alarms, hidden cameras, CCTV, access systems, fencing, locking systems).In all phases of collaboration, we focus closely on the clients’ needs while paying special attention to the need for confidentiality as well as cleaning services.
Any task is thus individually planned and executed to optimize team work and safety in accordance with the specific situation and the actual circumstances.</p>
<p data-aos="fade-up">We are driven by a passion to deliver the best security services and this progressively keeps us exploring the best practices in the sector to serve our customers better over time.</p>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/2477e43f-193a-4662-a7dc-e2fff20050c7.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/f14666f8-f014-4f51-ba1d-72f442bc3d75.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<p data-aos="fade-up">MIKUBA SECURITY AND PROJECTS strives to provide careful selected, trained, motivated and committed Security Officials who report for duty on time, who are sober, alert, neat in appearance and capable of responding to instructions.
Training and development, at all levels, are regarded as essential in the delivery of a quality service to customers, ensuring that personnel are competent and have the opportunity to advance in the company.</p>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/e01e754d-bc1a-45fc-8032-57e1411497d5.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<p data-aos="fade-up">We carry out intensive security training to convert our recruits into professional security guards. The training syllabus covers the following subjects:</p>
<ol data-aos="fade-up">
<li><p>Practical security guards practice- General drills and discipline</p></li>
<li><p>Traffic and parking control</p></li>
<li><p>First Aid</p></li>
<li><p>Fire-fighting and control skills</p></li>
<li><p>Basics in terrorism and counterterrorism skills.</p></li>
<li><p>Patrols, surveillance and counter surveillance skills</p></li>
<li><p>Customer care in security perspective</p></li>
<li><p>Basic principles of law and investigation (scene of crime prevention)</p></li>
<li><p>Searching of personnel &amp; vehicles etc.</p></li>
<li><p>Report writing, statement recording and note book (occurrence book) keeping.</p></li>
<li><p>Emergency procedures.</p></li>
</ol>
<blockquote data-aos="fade-up">
  <p>Further to the above general courses we train guards as per the specific assignment requirements and the clients’ instructions and come up with the standing orders, procedures for the assignments.</p>
</blockquote>
<p data-aos="fade-up">At the end of the training, the guards have a proper understanding of their core security duties as below:</p>
<ol data-aos="fade-up">
<li><p>Protection/securing of life and property/assets</p></li>
<li><p>Maintenance of law and order</p></li>
<li><p>Prevention, deterrent and detection of crime</p></li>
<li><p>Enforcement of company's (clients) rules and regulations</p></li>
<li><p>Assisting in pre-emergency and post emergency situations.</p></li>
<li><p>Keeping and maintenance of relevant records i.e. visitor’s goods received etc.</p></li>
<li><p>Maximum use of the available parking area and parking control.</p></li>
<li><p>Public relations and communication skills.</p></li>
<li><p>General searches on persons and vehicles etc.</p></li>
</ol>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/adfe2f5b-a81c-4fcf-b2ea-3c7087b3a606.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/ebb608e3-d88d-4d47-8839-77f98f496542.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<p data-aos="fade-up">The Company maintains a reasonable public liability/professional indemnity policy covering the customer against any potential loss or costs incurred as a result of any wilful negligence on the part of our employees. MIKUBA SECURITY AND PROJECTS is mindful of the fact that they are not an insurance Company but a security company that minimizes risks to its customers.</p>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/fcca2cbb-2459-4647-a900-2d60d722e740.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<p data-aos="fade-up">We have well trained and dedicated supervisors on motorbikes who visit guards at the assignment and check that guards are well turned out, understand what they are supposed to do, they are proactive while on duty and that they follow the assignment instructions.
The visits are done daily for all sites within the city and all others far from the city they ensure they visit at least once in a week. Our management team visits the assignments regularly to check on the quality of our</p>
<p data-aos="fade-up"><em>Note:
• The prices quoted above are not inclusive of VAT
• The prices are negotiable in line with the scope of services.</em></p>
<blockquote data-aos="fade-up">
  <p>We do all types of cleaning services!</p>
</blockquote>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/d18bd127-e84a-41b2-94a2-e29630173893.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/be13edc4-129c-4e55-af08-eacb32a46cda.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<ul data-aos="fade-up">
<li><p>Underground floor cleaning</p></li>
<li><p>Floor cleaning</p></li>
<li><p>Toilet cleaning</p></li>
<li><p>Windows cleaning</p></li>
<li><p>Carpet deep cleaning</p></li>
</ul>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/a317b09a-616a-4609-bad1-0709521a8123.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<ul data-aos="fade-up">
<li><p>Deep cleaning</p></li>
<li><p>Windows cleaning</p></li>
<li><p>Carpet deep cleaning</p></li>
<li><p>Kitchen cleaning</p></li>
<li><p>Toilet cleaning</p></li>
<li><p>After moving cleaning</p></li>
</ul>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/f510366e-4678-474a-b5c3-5fe9533ca0ee.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<ul data-aos="fade-up">
<li><p>Deep cleaning</p></li>
<li><p>Kitchen cleaning</p></li>
<li><p>Toilet cleaning</p></li>
<li><p>Windows cleaning</p></li>
<li><p>After moving cleaning</p></li>
</ul>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/9328b1f6-fe16-410a-9cc4-d96f41c707af.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<ul data-aos="fade-up">
<li><p>Movies events cleaning</p></li>
<li><p>Party events cleaning</p></li>
<li><p>Conferences events cleaning</p></li>
<li><p>Celebrations events cleaning</p></li>
</ul>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/5638c106-77ab-4ea6-82e4-e66d4c3b380a.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<ul data-aos="fade-up">
<li><p>Wash , disinfect and wipe the interior and exterior parts of the bus.</p></li>
<li><p>Swept and vacuumed the bus floor and carpeted area in an effective manner.</p></li>
<li><p>Detect and wipe off all finger marks and smudges from glass surfaces.</p></li>
</ul>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/af9e0fee-f46e-4fbc-b8d4-db5bf1d79d23.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<p data-aos="fade-up"><StaticImage src="../motionlink_media/images/06f7dd08-5bd4-48f3-b07b-c0540cd27fc7.png" alt="Mikuba security image" formats={["avif", "webp", "auto"]}></StaticImage></p>
<ol data-aos="fade-up">
<li><p>Price quotations for security systems will be done for clients after they advise on type of systems they would prefer to install and after a survey has been done.</p></li>
<li><p>For security fences, quotations for customers will be arrived at when our technical team has done a survey and arrived at the total distance of area to be covered, and also on the type and diameter of the fence selected plus other equipment or materials that may be required for the fence to operate effectively.</p></li>
<li><p>For other mentioned services, charges will be agreed upon discussion with the respective team involved.</p></li>
</ol>
<blockquote data-aos="fade-up">
  <p>At Mikuba 3 things we can assure you that:</p>
</blockquote>
<ol data-aos="fade-up">
<li><p>We are registered with the private security industry regulatory authority</p></li>
<li><p>Our staff are registered with PSIRA and fully trained</p></li>
<li><p>We are ready to suit your every need.</p></li>
</ol>
<blockquote data-aos="fade-up">
  <p>What is guaranteed with us?</p>
</blockquote>
<ul data-aos="fade-up">
<li><p>Competitive edge</p></li>
<li><p>The best service</p></li>
<li><p>The most affordable proposals.</p></li>
</ul>
<blockquote data-aos="fade-up">
  <p>If you are interested in saving when it comes to hiring security please send us your best proposal and we will provide you with one better, best price, and best service guaranteed!!!</p>
</blockquote>
    </div>
  )
}

export default HtmlContent;
