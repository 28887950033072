import * as React from "react"

function Footer() {
  return (
    <div className="container mt-5">
      <footer className="row text-center">
        <p>
          Copyright 2022 © Mikuba security and projects Limited. Designed by
          <a href="https://ellezeka.com/">Elle Zeka</a>
        </p>
      </footer>
    </div>
  )
}

export default Footer
