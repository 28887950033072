import * as React from "react"
import AOS from "../components/aos"
import ContactUsForm from "../components/contact_us_form"
import Customers from "../components/customers"
import Footer from "../components/footer"
import Header from "../components/header"
import HtmlContent from "../components/html_content"
import Seo from "../components/seo"
import "./index.css"

function Home() {
  return (
    <>
      <AOS />
      <Seo title="Mikuba Security and Projects in Cape Town" />
      <Header />
      <HtmlContent />
      <Customers />
      <ContactUsForm />
      <Footer />
    </>
  )
}

export default Home
