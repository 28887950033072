import * as React from "react"
import AOS_ from "aos/dist/aos"

class AOS extends React.Component {
  componentDidMount() {
    AOS_.init()
  }

  componentDidUpdate() {
    AOS_.refresh()
  }

  render() {
    return <></>
  }
}

export default AOS
