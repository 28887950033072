import { StaticImage } from "gatsby-plugin-image"
import React from "react"

function Header() {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <nav className="navbar fixed-top navbar-expand-md navbar-light justify-content-end">
            <button
              className="navbar-toggler me-2 bg-light"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse justify-content-center me-3"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav bg-light rounded">
                <li className="nav-item active">
                  <a href="#aboutus" className="nav-link">
                    About
                  </a>
                </li>

                <li className="nav-item active">
                  <a href="#services" className="nav-link">
                    Services
                  </a>
                </li>

                <li className="nav-item active">
                  <a href="#contact-us" className="nav-link">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div className="container-fluid header pt-5 pb-1">
        <div className="row justify-content-end">
          <div
            data-aos="zoom-in"
            data-aos-duration="1500"
            className="col-sm-12 col-md-4"
          >
            <div className="row">
              <div className="col-12 text-center text-light">
                <h2>
                  MIKUBA
                  <br />
                  SECURITY
                  <br />
                  AND
                  <br />
                  PROJECTS
                </h2>
              </div>
              <div className="col-12 mt-2 d-flex justify-content-center">
                <StaticImage
                  width={180}
                  src="../images/logo-white.png"
                  alt="Mikuba Security7 Services logo"
                  formats={["avif", "webp", "auto"]}
                />
              </div>
            </div>
          </div>
          <div
            data-aos="zoom-in"
            data-aos-duration="1500"
            className="col-12 d-flex justify-content-end mt-5"
          >
            <div className="col-6">
              <div className="col-12 col-lg-7">
                <h1 className="display-4">PROTECTING PLACES</h1>
                <h3 className="text-warning display-6">
                  PROVIDING PEACE OF MIND
                </h3>
                <p className="text-light">
                  <strong>
                    We at mikuba Security and projects, we are dedicated to
                    provide quality solutions for a secure enviroment so you
                    will have peace of mind!
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div
            data-aos="zoom-in"
            data-aos-duration="1500"
            className="col-12 d-flex justify-content-center"
          >
            <p className="bg-light text-warning">
              <strong>Quality Solutions for a Secure Environment</strong>
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row mission-and-vision">
          <div data-aos="fade-up" className="col-sm-12 col-md-6">
            <StaticImage
              src="../images/securty_look_away.jpeg"
              alt="Mikuba Security7 Services logo"
              formats={["avif", "webp", "auto"]}
            />
          </div>
          <div data-aos="fade-up" className="col-sm-12 col-md-6 text-center">
            <div className="col-12 mt-sm-3">
              <h3>Our Mission</h3>
              <p>{"To provide our customers with the best possible security services and advisory services driven by a passion to solve operational and security issues promptly efficiently and at a reasonable cost."}</p>
            </div>
            <div data-aos="fade-up" className="col-12">
              <h3>Our Vision</h3>
              <p>{"To be a leading professional security company in the provision of security services with integrity within South Africa and beyond Contact Us."}</p>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" className="row mt-5 bg-warning p-4">
          <div className="col-sm-12 col-md-6 text-primary text-sm-center text-md-start">
            <strong>
              INTRODUCTION TO MIKUBA SECURITY AND PROJECTS A SECURITY COMPANY
              INCOPORATED IN SOUTH AFRICA HENCE OFFERING MANNED GUARDING
              SERVICES, ELECTRIC FENCING SOLUTION, TECHNOLOGY BASED SECURITY
              SYSTEMS AND COVERT SURVEILLANCE SYSTEMS.
            </strong>
          </div>
          <div className="col-sm-12 col-md-6 mt-sm-3 mt-md-1 d-flex justify-content-center align-items-center">
            <a
              className="bg-primary text-light text-decoration-none py-2 px-3 rounded"
              href="#contact-us"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
